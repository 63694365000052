<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <view-skeleton>
      <bank-account />
    </view-skeleton>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import BankAccount from '@/components/bank-account/bank-account';

import DefaultHeadMixin from '@/mixins/defaultHead';
import ViewSkeleton from '@/components/utils/view-skeleton';

export default {
  mixins: [DefaultHeadMixin],
  components: {
    DefaultLayout,
    BankAccount,
    ViewSkeleton,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: this.$t('breadcrumb.bankSetting'),
          to: this.$router.currentRoute.path,
        },
      ];
    },
  },
  methods: {},
  async mounted() {},
};
</script>
